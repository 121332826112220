/* @charset "utf-8"; */
/* CSS Document */

// IMPORT - START

@import form-initialization-general

// IMPORT - END


// LABEL - START

/* GENERAL - START */

form
	div
		h3
			font-family: $URL_FAMILYTERTIARY_SECONDARY
		fieldset
			legend
				font-family: $URL_FAMILYTERTIARY_PRIMARY
			label
				font-family: $URL_FAMILYTERTIARY_PRIMARY
			label.LabelPreffix,	label.LabelSuffix
				font-family: $URL_FAMILYTERTIARY_SECONDARY
			span
				font-family: $URL_FAMILYTERTIARY_SECONDARY

div.DivBodyGeneral, div.DivVerifierGeneral, div.DivCenterGeneral
	header.HeaderGeneral
		h2
			font-family: $URL_FAMILYTERTIARY_SECONDARY
		p
			font-family: $URL_FAMILYTERTIARY_PRIMARY
	div.DivForm
		h3, h4
			font-family: $URL_FAMILYTERTIARY_SECONDARY
		label, .legend-title
			font-family: $URL_FAMILYTERTIARY_PRIMARY
		span, p
			font-family: $URL_FAMILYTERTIARY_SECONDARY
		textarea#spanIncidentNote
			font-family: $URL_FAMILYTERTIARY_SECONDARY
		textarea.TextareaDescriptionHeader, textarea.TextareaConversationDisable
			font-family: $URL_FAMILYTERTIARY_PRIMARY
	div.DivCenterContainer
		div.DivForm
			div.DivSubHeaderHeadline
				label, h2
					font-family: $URL_FAMILYTERTIARY_SECONDARY
			div.DivHeaderStatus
				span
					font-family: $URL_FAMILYTERTIARY_PRIMARY
			div.DivFormHorizontalContainer
				div.DivHeaderInfo
					dl
						dt
							font-family: $URL_FAMILYTERTIARY_PRIMARY
						dd
							font-family: $URL_FAMILYTERTIARY_SECONDARY
				div.DivHeaderList
					ul
						li
							span
								font-family: $URL_FAMILYTERTIARY_PRIMARY

div.DivProfileGeneral
	div.DivProfileInfo
		div.DivProfileInfoDetails
			span
				font-family: $URL_FAMILYTERTIARY_SECONDARY

div.DivRoundButtonContainer
	label
		font-family: $URL_FAMILYTERTIARY_SECONDARY !important

section.SectionSecondary
	div.DivSiteContainer
		a.ADetail
			font-family: $URL_FAMILYTERTIARY_TERTIARY
	div#divParagraphLanding
		h3
			font-family: $URL_FAMILYTERTIARY_TERTIARY
	div.DivContentImage
		div.DivProduct
			label
				font-size: 1em !important

/* GENERAL - END */

/* NAVIGATION - START */

nav
	figure
		span#spanProfileNameInitial
			font-family: $URL_FAMILYSECONDARY_PRIMARY
		figcaption
			span#spanProfileName
				font-family: $URL_FAMILYTERTIARY_SECONDARY
			span#spanProfilePosition
				font-family: $URL_FAMILYTERTIARY_PRIMARY
	ul
		li
			font-family: $URL_FAMILYTERTIARY_PRIMARY
	a
		font-family: $URL_FAMILYTERTIARY_PRIMARY
	div
		a
			font-family: $URL_FAMILYTERTIARY_PRIMARY
	li:hover, li.ListNavigationMenuActive
		a
			font-family: $URL_FAMILYTERTIARY_TERTIARY

/* NAVIGATION - END */

/* SIGN IN - START */

div#divBodySignIn
	header#headerSignIn
		h1
			font-family: $URL_FAMILYSECONDARY_PRIMARY
		span
			font-family: $URL_FAMILYTERTIARY_PRIMARY
	form
		div.DivNotRegistered
			font-family: $URL_FAMILYTERTIARY_PRIMARY
div.DivSignInContainer
	h1, h2, h3, h4, span
		font-family: $URL_FAMILYTERTIARY_SECONDARY
	p, label, a, input, select, textarea, label span
		font-family: $URL_FAMILYTERTIARY_PRIMARY

/* SIGN IN - END */

/* DASHBOARD - START */

div#divMainDashboard
	div.DivHighlight
		span.SpanHighlight
			font-family: $URL_FAMILYTERTIARY_SECONDARY

form div.DivForm fieldset, div.DivForm
	div.DivFormHorizontalContainer, div.DivFormVerticalContainer, div.DivFormListHorizontalContainer
		div.DivListChart
			font-family: $URL_FAMILYTERTIARY_SECONDARY

/* DASHBOARD - END */

/* TABLE - START */

div.DivTableContainer, div.DivTableListContainer, div.DivTableListContainerLeft
	span.SpanTablePage
		font-family: $URL_FAMILYTERTIARY_PRIMARY
	ul.ListTableThumbnails
		figure
			figcaption
				font-family: $URL_FAMILYTERTIARY_PRIMARY
	ul.ListTableTicket, ul.ListTableConversation
		span.SpanTicketNumber, span.SpanConversationDate
			font-family: $URL_FAMILYTERTIARY_PRIMARY
		span.SpanTicketTitle, span.SpanConversationTitle
			font-family: $URL_FAMILYTERTIARY_SECONDARY
		dl
			dt
				font-family: $URL_FAMILYTERTIARY_PRIMARY
			dd
				font-family: $URL_FAMILYTERTIARY_SECONDARY
		li
			div.DivConversationProfile
				span.SpanChatProfileNameInitial
					font-family: $URL_FAMILYSECONDARY_PRIMARY

div.DivTableContainer
	th, td
		font-family: $URL_FAMILYTERTIARY_PRIMARY


/* TABLE - END */

/* LOADING - START */

div#divCurtainLoading
	span#spanLoadingPercentage
		font-family: $URL_FAMILYTERTIARY_SECONDARY
	span
		font-family: $URL_FAMILYTERTIARY_PRIMARY

/* LOADING - END */

// LABEL - END


// INPUT - START

/* GENERAL - START */

form
	div
		fieldset
			input, textarea
				font-family: $URL_FAMILYTERTIARY_PRIMARY
			div.DivConversationHeader
				span.SpanConversationDate
					font-family: $URL_FAMILYTERTIARY_PRIMARY
				span.SpanConversationTitle
					font-family: $URL_FAMILYTERTIARY_SECONDARY
	label.DivLabelForPrice
		font-family: $URL_FAMILYTERTIARY_TERTIARY

div.DivFormSingleDropdown
	label
		font-family: $URL_FAMILYTERTIARY_TERTIARY

div.DivInputHorizontalContainer
	label
		font-family: $URL_FAMILYTERTIARY_TERTIARY

div.DivFormTextarea
	label
		font-family: $URL_FAMILYTERTIARY_TERTIARY

/* GENERAL - END */

// INPUT - END


/* THIRD PARTY */

// DROPDOWN MULTIPLE -START

div.DivForm
	div.DivFormMultipleDropdown,div.DivFormSingleDropdown
		span, p
				font-family: $URL_FAMILYTERTIARY_PRIMARY

// DORPDOWN MULTIPLE -END

// MONITORING RENEWAL - START

div.DivCardPolicy
	span, h2
		font-family: $URL_FAMILYTERTIARY_SECONDARY

div.DivPolicyAttributes
	span
		font-family: $URL_FAMILYTERTIARY_PRIMARY

div.DivCardInformationPolicy
	div.DivPolicyInfo
		span
			font-family: $URL_FAMILYTERTIARY_SECONDARY

// MONITORING RENEWAl - END

div#divMainLandingContainer
	section.SectionMain
		h1, h2, h3, h4, h5
			font-family: $URL_FAMILYTERTIARY_SECONDARY !important

div#divMainDashboard, input
	font-family: $URL_FAMILYTERTIARY_PRIMARY
	h1, h2, h3, h4, h5
		font-family: $URL_FAMILYTERTIARY_SECONDARY
	ul.UlTabContainer
		li.ListTab
			font-family: $URL_FAMILYTERTIARY_TERTIARY
	div.DivProduct
		text-align: center
		label
			font-family: $URL_FAMILYTERTIARY_SECONDARY
	div.DivPaymentExpired
		span.SpanWrapper
			font-family: $URL_FAMILYTERTIARY_PRIMARY
	div.DivFormHorizontalContainer
		span.SpanPremiumTotalAMount
			font-family: $URL_FAMILYTERTIARY_SECONDARY

// ANGULAR MATERIAL - START

.mat-checkbox
	p
		font-family: $URL_FAMILYTERTIARY_PRIMARY !important
.mat-label
	font-family: $URL_FAMILYTERTIARY_PRIMARY !important

// ANGULAR MATERIAL - END