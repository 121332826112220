/* @charset "utf-8"; */
/* CSS Document */

// IMPORT - START

@import form-initialization-general

// IMPORT - END


// GENERAL - START

form
	div.DivForm
		h3
			margin-bottom: $SPACE_LITTLE
			font-size: $FONT_LABEL_H3
		fieldset
			legend
				font-size: $FONT_LABEL_LEGEND
				margin-top: $SPACE_LITTLE
				margin-bottom: $SPACE_LITTLE
			label
				font-size: $FONT_LABEL_GENERAL
				margin-bottom: $SPACE_NARROW
				margin-top: $SPACE_SMALL
				display: block
			label.LabelPreffix
				margin-right: $SPACE_LITTLE
			label.LabelSuffix
				margin-left: $SPACE_LITTLE
			span.SpanActiveStatus, i.IActiveStatus
				margin-right: $SPACE_MEDIUM
				vertical-align: middle
				display: inline-block
				padding-top: $SPACE_TINY
				padding-left: $SPACE_LITTLE
				padding-right: $SPACE_LITTLE
				padding-bottom: $SPACE_TINY
				font-size: $FONT_SPAN_TICKETSTATUS
				margin: 0px
				+setBorderRadius($RADIUS_TICKET_STATUS)
		span.SpanCustomerPhone
			display: flex !important
			select
				width: $WIDTH_INPUT_PHONE
				margin-right: $SPACE_LITTLE
	label.LabelAttachment
		width: $SIZE_ICON_LDPI
		height: $SIZE_ICON_LDPI
		+setBorderRadius(50%)

div.DivBodyGeneral, div.DivVerifierGeneral, div.DivCenterGeneral
	header.HeaderGeneral
		h2
			font-size: $FONT_LABEL_H2
		span
			font-size: $FONT_LABEL_SUBTITLE
	div.DivForm
		h2, h3
			display: block
			margin-bottom: $SPACE_LITTLE
		h2
			margin-top: $SPACE_LITTLE
			font-size: $FONT_LABEL_H2
		h3
			margin-top: $SPACE_MEDIUM
			font-size: $FONT_LABEL_H3
		h3:first-of-type
			margin-top: 0px
		h4
			margin-top: $SPACE_MEDIUM
			font-size: $FONT_LABEL_H4
		h4:first-of-type
			margin-top: 0px
		label
			font-size: $FONT_LABEL_GENERAL
			margin-bottom: $SPACE_NARROW
			margin-top: $SPACE_SMALL
			display: block
			i
				font-size: 15px
				font-weight: 550
		label.mat-form-field-label
			line-height: auto
			margin-top: -$SPACE_LITTLE
			margin-bottom: 0px
		div.DivHeaderHeadline
			div.DivSubHeaderHeadline
				label
					margin-top: 0
		span, p, textarea#spanIncidentNote
			font-size: $FONT_LABEL_PROPERTY
			margin-bottom: $SPACE_SMALL
			margin-top: $SPACE_NARROW
			display: block
		span, p
			word-break: break-all
		span.SpanRupiah
			font-size: $FONT_LABEL_PROPERTY
			margin-bottom: $SPACE_SMALL
			margin-top: $SPACE_NARROW
			word-break: inherit
		.mat-form-field, .legend-wrap
			span
				font-size: inherit
				margin-bottom: 0px
				margin-top: 0px
				display: inline-block
				.legend-label-text
					word-break: break-word
					white-space: normal
		.legend-wrap
			.horizontal-legend
				li
					display: block
		dl
			dt, dd
				font-size: $FONT_LABEL_HEADERINFO
		div.DivHeaderHeadline
			div.DivHeaderStatus
				span
					font-size: $FONT_LABEL_SUBTITLE
		fieldset
			div.DivHeaderInfo
				dl
					dt, dd
						font-size: $FONT_DATALIST_HEADERINFO

div.DivProfileInfo
	div.DivProfileInfoDetails
		a.Underline
			text-decoration: underline
	div.DivProfilePhoto
		span.SpanChatProfileNameInitial
			margin: 0px
			font-family: $URL_FAMILYSECONDARY_PRIMARY
			color: white
			font-size: $FONT_SPAN_PROFILENAMEINITIAL
			display: block
			text-align: center

div#divBodyAbout
	div.DivContentMain
		div.DivContentAbout
			p
				line-height: 38px
				text-align: justify
	div.DivContentDescription
		display: flex
		margin-top: 20px
		margin-bottom: 20px
		h1
			display: block
			font-size: 3.5em
			margin-top: 20px
		p
			font-size: 1.1em
			font-weight: 550
	div#divContentWhatBRINS
		h1
			font-size: 3em
		div.DivContentAbout
			p
				line-height: 38px

// GENERAL - END


// LANDING - START

div#divBodyDashboard1
	div.DivContentDashboard
		div.DivContentSection
			div.DivContentTitle
				h3
					font-size: 4em
					font-weight: bold
					color: white
					line-height: 1.4
			div.DivContentDescription
				p
					font-size: 1.25em
					color: white

// LANDING - END


// NAVIGATION - START

nav
	figure
		text-align: center
		span#spanProfileNameInitial
			font-size: $FONT_LABEL_PROFILENAMEINITIAL
			display: block
		figcaption
			span#spanProfileName, span#spanProfilePosition
				display: block
			span#spanProfileName
				font-size: $FONT_LABEL_PROFILENAME
			span#spanProfilePosition
				font-size: $FONT_LABEL_PROFILEPOSITION
	ul
		list-style-type: none
		// li
		// 	margin-top: $SPACE_LARGE
		// 	margin-bottom: $SPACE_LARGE
	div
		div
			a
				text-decoration: none
	ul.ListNavigation
		li.ListItemNavigation
			font-size: $FONT_LIST_NAVIGATIONITEM
			ul.ListSubNavigation, ul.ListSubNavigationCalculatePremium
				li.ListItemSubNavigation
					font-size: $FONT_LIST_NAVIGATIONSUBITEM

// NAVIGATION - END


// SIGN IN - START

div#divBodySignIn
	header#headerSignIn
		h1, span
			display: block
		h1
			font-size: $FONT_LABEL_H1
			letter-spacing: $SPACE_LITTLE
			margin-bottom: -$SPACE_LITTLE
		span
			font-size: $FONT_LABEL_SPAN
	form
		div.DivNotRegistered
			text-align: center
			font-weight: 500

// SIGN IN - END


// DASHBOARD - START

div#divMainDashboard, div#divBodyDashboard
	div.DivHighlight
		margin-top: $SPACE_MEDIUM
		margin-bottom: $SPACE_MEDIUM
		text-align: center
		span.SpanHighlight
			padding-top: $SPACE_LITTLE
			padding-bottom: $SPACE_LITTLE
			padding-left: $SPACE_MEDIUM
			padding-right: $SPACE_MEDIUM
			margin: $SPACE_LITTLE
			min-width: $WIDTH_LABEL_HIGHLIGHT
			display: block
			+setBorderRadius($RADIUS_LABEL_HIGHLIGHT)
	div.DivFormHorizontalContainer
		span.SpanPremiumTotalAMount
			font-size: $FONT_LABEL_TITLE_CLAIM

form div.DivForm fieldset, div.DivForm
	ul
		list-style: none
	div.DivFormHorizontalContainer, div.DivFormVerticalContainer, div.DivFormListHorizontalContainer
		div.DivListChart
			span
				font-size: $FONT_DASHBOARD_SPAN
			ul
				padding: $SPACE_LITTLE
				+setBorderRadius($RADIUS_DASHBOARD_LIST)
				li
					list-style: none
					margin-top: $SPACE_NARROW
					margin-bottom: $SPACE_LITTLE
					span
						font-size: $FONT_DASHBOARD_SPANLIST
						display: inline
					span:first-of-type
						+setBorderRadius($RADIUS_DASHBOARD_LIST)
						padding: $SPACE_TINY $SPACE_LITTLE
						margin: $SPACE_TINY

// DASHBOARD - END


// TABLE - START

table
	thead, tbody
		tr
			th
				font-weight: 500
				font-size: $FONT_SPAN_TABLE_HEAD
			td
				font-weight: 500
				font-size: $FONT_SPAN_TABLE_HEAD
				text-align: left
				span
					font-size: $FONT_SPAN_TABLE
			td.TableCenter
				text-align: center

div.DivTableContainer
	div.DivTableControl
		span.SpanTablePage
			margin-left: $SPACE_MEDIUM
			margin-right: $SPACE_MEDIUM
			font-size: $FONT_SPAN_TABLE
		label
			margin-right: $SPACE_MEDIUM
			white-space: nowrap
			font-size: $FONT_SPAN_TABLE
	ul.ListTableThumbnails
		figure
			figcaption
				font-size: $FONT_LABEL_GENERAL
	ul.ListTableTicket, ul.ListTableConversation
		div.DivTicketItem, div.DivConversationGeneral
			span.SpanTicketTitle
				font-size: $FONT_LIST_TABLETITLE
			div.DivTicketSubItem, div.DivConversationItem
				div.DivTicketHeader, div.DivConversationHeader
					span
						display: block
					span.SpanTicketNumber, span.SpanConversationDate
						font-size: $FONT_DATA_TICKETLABEL
					span.SpanTicketTitle, span.SpanConversationTitle
						font-size: $FONT_LIST_TABLETITLE
						display: block
					span.SpanConversationDate
						text-align: right
					span.SpanTicketNumber
						display: inline-flex
						p
							margin-left: 5px
							border-left: solid 3px white
							padding-left: 5px
				div.DivTicketTail, div.DivConversationStatus
					span
						margin-right: $SPACE_MEDIUM
						vertical-align: middle
						display: inline-block
						padding-top: $SPACE_TINY
						padding-left: $SPACE_LITTLE
						padding-right: $SPACE_LITTLE
						padding-bottom: $SPACE_TINY
						font-size: $FONT_SPAN_TICKETSTATUS
						+setBorderRadius($RADIUS_TICKET_STATUS)
					span:first-of-type
						margin-left: 0px
						margin-bottom: $SPACE_LITTLE
					span:last-of-type
						margin-right: 0px
		li.LiConversationContainerLeft
			div.DivConversationGeneral
				div.DivConversationItem
					div.DivConversationHeader
						span.SpanConversationDate
							text-align: left
		dl
			dt, dd
				margin-left: 0px
				margin-right: 0px
				margin-bottom: $SPACE_THIN
				margin-top: $SPACE_THIN
				font-size: $FONT_DATA_TICKETLABEL
		li
			div.DivConversationProfile
				span.SpanChatProfileNameInitial
					font-size: $FONT_SPAN_PROFILENAMEINITIAL
					display: block
	table.TableListDetails
		font-size: 0.9em
		font-weight: 600
	table.TableListDetailsAttachment
		input[type=file]
			display: none
		label.LabelAttachment
			display: block
			width: $SIZE_ICON_LDPI
			height: $SIZE_ICON_LDPI
			+setBorderRadius(50%)
		div.DivContentButtonDetail
			text-align: center
			a.ButtonForward
				span
					line-height: 18px
					font-weight: 600
					vertical-align: middle
					font-size: 1.2em

	table.TableBox
		tbody
			td
				text-align: center

// TABLE - END


// DYNAMIC CONTAINER - START

div.DivBodyGeneral, div.DivVerifierGeneral, div.DivCenterGeneral
	div.DivForm
		div.DivListControl
			h3
				margin: 0px
			div.DivListControlButton
				label
					margin-left: $SPACE_LITTLE
					margin-right: $SPACE_LITTLE
					font-size: $FONT_SPAN_TABLE
				label, input[type=button]
					display: inline-block

div.DivDynamicContainer
	form.FormInsertConversation
		div.DivConversationGeneral
			div.DivConversationHeader
				h3
					margin: 0
				div.DivConversationInfo
					div.DivConversationInfoDetails
						span
							margin: 0px
							text-align: right
			div.DivConversationContent
				div.DivAttachmentContainer
					label
						margin: 0px
			div.DivConversationProfile
				span.SpanChatProfileNameInitial
					margin: 0px
					font-family: $URL_FAMILYSECONDARY_PRIMARY
					color: white
					font-size: $FONT_SPAN_PROFILENAMEINITIAL
					display: block
					width: $SPACE_BIG
					height: $SPACE_ENORMOUS
					padding-top: $PADDING_SPAN_CONVERSATIONPROFILE
					text-align: center

div#divBodyClaimProposal
	div#divMainClaimProposalDetailApproval
		div.DivDynamicContainer
			margin-right: $SPACE_SMALL
			form
				margin-right: 0px
			div.DivFormListSurveyModel, div.DivFormListSurveyThirdPartyLiability
				label.LabelClaimLetterStatus, label.LabelClaimSurveyStatus
					a.NotVerified, a.Verified
						font-size: $FONT_LABEL_SPAN

// DYNAMIC CONTAINER - END


// NOTIFICATION - START

div#divNotificationInstall, div#divNotificationInstallList
	span
		font-size: 1em
		font-weight: bold
	input#buttonNotificationClose
		font-size: $FONT_SIZE_CLOSE
	p#paragraphNotificationInstallIOS
		display: none
	p.ParagraphNotificationInstallOthers
		font-size: 0.7em
div#divNotificationInstallList
	span
		display: block
		margin-bottom: $SPACE_TINY

// NOTIFICATION - END


// LOADING - START

div#divCurtainLoading
	div#divLoadingMessage
		span#spanLoadingPercentage
			margin-bottom: $SPACE_HUGE
			font-size: $FONT_SPAN_LOADINGPERCENTAGE
		span
			text-align: center
			font-size: $FONT_LABEL_GENERAL

// LOADING - END


// APPROVAL - START

div.DivForm
	div.DivCard, div.DivCardFinish
		span#spanTitle
			font-size: 12pt
		span#spanName
			margin-top: -7.5px
			font-size: 16pt

// APPROVAL - END


// RETURN AMOUNT START

div.DivForm
	div.DivFormReturnAmount
		fieldset
			h3:first-of-type
				margin-top: -15px
			h3
				margin-top: -15px
				font-size: 12pt
				align-items: center


// RETURN AMOUNT END


// INFO WINDOWS GOOGLE MAPS PLATFORM START

div.DivDynamicContainer
	map-info-window
		a
			font-weight: bold
			margin-bottom: $SPACE_LITTLE
		p
			margin-top: $SPACE_LITTLE

div.DivFormBranch
	span
		font-weight: bold
		text-transform: capitalize
		
// INFO WINDOWS GOOGLE MAPS PLATFORM END

// CARD INSIDE GOOGLE MAPS FOR BRANCH MENU DETAIL START //

#divMainDashboard
	div.DivFormCardInside
		div.DivFormBranch
			p
				position: absolute
				font-size: $SPACE_MEDIUM
				right: 0
				margin-top: 18px
				margin-right: 155px

// CARD INSIDE GOOGLE MAPS FOR BRANCH MENU DETAIL START //

// BRINS DIGITAL - START

div#divMainDashboard
	p
		font-size: $FONT_PARAGRAPH_GENERAL
		line-height: $FONT_PARAGRAPH_LINE_HEIGHT
	p.ParagraphSmall
		font-size: $FONT_PARAGRAPH_GENERAL_SMALL
		line-height: $FONT_PARAGRAPH_LINE_HEIGHT_SMALL
	label
		font-size: $FONT_LABEL_GENERAL
	h1
		font-size: $FONT_HEADING_ONE_GENERAL
	h2
		font-size: $FONT_HEADING_TWO_GENERAL
	h3
		font-size: $FONT_HEADING_THREE_GENERAL
	h4
		font-size: $FONT_HEADING_FOUR_GENERAL
	h5
		font-size: $FONT_HEADING_FIVE_GENERAL
	h1, h2
		margin-top: $SPACE_NONE
		margin-bottom: $SPACE_SMALL
	h3, h4, h5
		margin-top: $SPACE_NONE
		margin-bottom: $SPACE_LITTLE
	p, label
		margin: $SPACE_LITTLE $SPACE_NONE
	h1
		line-height: $FONT_PARAGRAPH_LINE_HEIGHT_BIG
	h2
		line-height: $FONT_PARAGRAPH_LINE_HEIGHT
	div.DivProduct
		text-align: center
	span.SpanPaymentLight
		font-size: $FONT_HEADING_TWO_GENERAL
	label.LabelPromoLight
		text-align: center
	div.DivPaymentExpired
		span
			font-size: $FONT_HEADING_TWO_GENERAL
		span.SpanNonWrapper
			padding: $SPACE_THIN $SPACE_NARROW
		span.SpanWrapper
			padding: $SPACE_THIN $SPACE_NARROW
			border-radius: $SPACE_LITTLE

ul.UlTabContainer
	li.ListTab
		font-size: $FONT_SELECT_SEVERITY
		text-align: center

div#divBodyFooterContainer
	div.DivFooterInfoPage
		a
			text-decoration: none
		label
			font-size: $FONT_LABEL_FOOTER !important
		a.AFooterSVGContainer
			img
				width: $WIDTH_LAYOUT_LOGOHEADER
		p
			font-size: $FONT_INPUT_TEXTAREA_HEADERHEADLINE
			font-weight: 550
			line-height: $FONT_PARAGRAPH_LINE_HEIGHT
			margin: 0px
		div.DivFooterCopyright
			p.PLabelCopyright
				font-weight: 600
			p.PTextCopyright
				font-size: $FONT_LIST_NAVIGATIONSUBITEM
				line-height: $SIZE_LINK_BUTTONATTACHMENT
			hr
				height: 2px
div.DivContentBox
	h3
		margin: $SPACE_NARROW AUTO

div#divMainSectionContainer
	section#sectionPackageProduct
		div.DivContentDetailCoverage
			p
				margin: $SPACE_NONE
				line-height: 38px

ol.OlHorizontalStepperContainer
	h3
		font-size: $FONT_PARAGRAPH_GENERAL_SMALL

.mat-checkbox
	p
		font-size: $FONT_LABEL_SPAN !important
		line-height: 25px


label.LabelFieldNote
	margin-top: 0 !important
	i
		font-size: 0.85em !important
		font-weight: 550

// BRINS DIGITAL - END